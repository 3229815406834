<template>
    <section>
        <div id="multi-document-validation-modal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="close"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title uk-text-center">Validate Multiple Documents</h2>
                </div>
                <div class="uk-modal-body">
                    <p class="uk-margin-remove">Please select documents to validate</p>
                    <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                        <thead style="background: #0ABAB5; border-color: #0ABAB5;">
                            <tr>
                                <th class="uk-text-nowrap" style="color: #ffffff; font-weight: normal;">
                                    Document Type
                                </th>
                                <th class="uk-text-center" style="color: #ffffff; font-weight: normal;">
                                    Valid
                                </th>
                                <th class="uk-text-center" style="color: #ffffff; font-weight: normal;">
                                    Invalid
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(doc, index) in documentList" :key="index">
                                <td class="uk-text-nowrap">{{ doc.label }}</td>
                                <td class="uk-text-center">
                                    <label
                                        :for="doc.value"
                                        class="uk-border-circle"
                                        style="border-color: #979797; width: 20px; height: 20px;"
                                    >
                                        <input
                                            :name="doc.value"
                                            :id="doc.value"
                                            type="radio"
                                            class="uk-radio"
                                            @change="validate(doc.value, true)"
                                        />
                                    </label>
                                </td>
                                <td class="uk-text-center">
                                    <label
                                        :for="doc.value"
                                        class="uk-border-circle"
                                        style="border-color: #979797; width: 20px; height: 20px;"
                                    >
                                        <input
                                            :name="doc.value"
                                            :id="doc.value"
                                            type="radio"
                                            class="uk-radio"
                                            @change="validate(doc.value, false)"
                                        />
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <label for="validationNotes" class="uk-form-label">Notes <b class="uk-text-danger">*</b></label>
                    <textarea
                        id="validationNotes"
                        name="validationNotes"
                        rows="3"
                        class="uk-textarea"
                        v-model="notes"
                    ></textarea>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button
                        :class="[
                            !notes ? 'uk-button-default' : 'uk-button-primary',
                            'uk-button  uk-margin-small-left uk-modal-close'
                        ]"
                        type="button"
                        @click="doValidateDocument"
                        :disabled="!notes"
                    >Save</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess, notificationDanger, notificationDangerCustom } from "@/utils/notification";

export default {
    props: {
        userId: {
            type: String,
            required: true,
        },
    },
    data(){
        return {
            documentList: [
                { label: 'Foto KTP', value: 'other_document_ktp', api: "KTP" },
                { label: 'Foto Kartu Keluarga', value: 'other_document_kk', api: "KK" },
                { label: 'Foto Ijazah Terakhir', value: 'other_document_ijazah', api: "Ijazah" },
                { label: 'Foto Buku tabungan', value: 'other_document_bank_account', api: "Bank Account" },
                { label: 'Pas Photo', value: 'other_document_identification_photo', api: "Identification Photo" },
                { label: 'Foto SIM', value: 'other_document_sim', api: "SIM" },
                { label: 'Foto STNK', value: 'other_document_stnk', api: "STNK" },
                { label: 'Foto SKCK', value: 'other_document_skck', api: "SKCK" },
                { label: 'Foto Kartu BPJS Kesehatan', value: 'other_document_bpjs_kes_photo', api: "BPJS Kes" },
                { label: 'Kartu BPJS Ketenagakerjaan', value: 'other_document_bpjs_tk_photo', api: "BPJS TK" },
                { label: 'CV', value: 'other_document_cv', api: "CV" },
                { label: 'Foto NPWP', value: 'other_document_npwp', api: "NPWP" },
                { label: 'Foto Paklaring', value: 'other_document_paklaring', api: "Paklaring" },
                { label: 'Personal Guarantee', value: 'other_document_personal_guarantee', api: "Personal Guarantee" },
            ],
            notes: '',
            selectedDocs: {},
            isSubmit: false,
        };
    },
    async mounted() {
        await window.UIkit.modal('#multi-document-validation-modal').show();
    },
    methods: {
        ...mapActions({
            validateMultipleDocument: 'other_document/validateMultipleDocument',
        }),
        validate(doc, value) {
            this.selectedDocs = {
                ...this.selectedDocs,
                [doc]: value
            };
        },
        async doValidateDocument() {
            try {
                this.isSubmit = true;
                let payload = {
                    notes: this.notes
                };
                for (const doc in this.selectedDocs) {
                    payload[doc] = this.selectedDocs[doc];
                }

                const result = await this.validateMultipleDocument({
                    user_id: this.userId,
                    payload: payload
                });

                if (result && result.status === 'OK') {
                    notificationSuccess(`Validation successful`);
                } else {
                    notificationDangerCustom('Failed to validate!');
                }
            } catch (error) {
                notificationDanger(error);
            }

            this.isSubmit = false;
            this.close();
        },
        async close() {
            await window.UIkit.modal('#multi-document-validation-modal').$destroy(true);
            this.$emit('close');
        },
    }
};
</script>
